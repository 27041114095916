<script setup lang="ts">
import type { CmsBlockTextTwoColumn } from "@shopware/composables";
import { useCmsBlock } from "#imports";

const props = defineProps<{
  content: CmsBlockTextTwoColumn;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");
</script>

<template>
  <section class="zebra-content">
    <div class="container">
      <div class="grid md:grid-cols-2 gap-10 align-top items-start">
        <CmsGenericElement
            :content="leftContent"
            class="flex flex-col gap-8 md:gap-5"
        />

        <CmsGenericElement
            :content="rightContent"
            class="flex flex-col gap-5"
        />
      </div>
    </div>
  </section>
</template>
